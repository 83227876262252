/*
* ink colors (with variations only for black and white)
*/
.btn-action-root {
  position: relative; }
  .btn-action-root .btn-action-container {
    align-items: center;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #D6D6D6;
    color: rgba(24, 59, 86, 0.8);
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    gap: 4px;
    height: 28px;
    letter-spacing: 0.2px;
    line-height: 24px;
    padding: 8px 12px;
    text-transform: capitalize; }
    .btn-action-root .btn-action-container img {
      filter: brightness(0) saturate(100%);
      height: 15px;
      width: auto; }
    .btn-action-root .btn-action-container .btn-action-arrow {
      font-size: 12px;
      font-weight: 900; }
    .btn-action-root .btn-action-container.active {
      background-color: #E4EDFB;
      border: 1px solid #E4EDFB;
      color: #1d66dd;
      font-weight: 700; }
      .btn-action-root .btn-action-container.active img {
        filter: brightness(0) saturate(100%) invert(31%) sepia(92%) saturate(1420%) hue-rotate(202deg) brightness(87%) contrast(99%); }
  .btn-action-root .btn-action-modal {
    background: var(--Secondary-White-100, #fff);
    border-radius: 8px;
    box-shadow: 2px 4px 16px 0px rgba(163, 177, 198, 0.4);
    left: 0;
    padding: 20px 16px;
    position: absolute;
    top: 60px;
    width: auto;
    z-index: 1; }
